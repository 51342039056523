(function () {
    angular.module('informaApp')
        .component('newFeatureList', {
            templateUrl: 'components/admin-sections/whats-new-section/new-feature-list/template.html',
            controller: NewFeatureList,
            bindings: {
                onEdit: '<'
            }
        });

    function NewFeatureList(NewFeatureService, NewFeatureFileTypes, $scope) {
        this.fileTypes = NewFeatureFileTypes;
        this.featureList = [];

        this.canChangeStatus = true;

        NewFeatureService.getAll().then((features) => {
            this.featureList = features;

            this.featureGroups = _.chunk(this.featureList, 3);
            this.canChangeStatus = canChangeActiveStatus(this.featureList);
        });

        this.onActiveStatusToggle = (feature) => {
            if (!feature.isActive && !canChangeActiveStatus(this.featureList)) {
                return;
            }

            feature.isActive = !feature.isActive;
            this.canChangeStatus = false;

            NewFeatureService.changeActiveStatus(feature.id, feature.isActive)
                .catch(() => {
                    feature.isActive = !feature.isActive;
                })
                .finally(() => this.canChangeStatus = canChangeActiveStatus(this.featureList));
        }

        this.onRemoveStatusToggle = (feature) => {
            if (this.isRemovingInProgress) {
                return;
            }

            feature.isRemoved = !feature.isRemoved;
            this.isRemovingInProgress = true;

            NewFeatureService.changeRemoveStatus(feature.id, feature.isRemoved)
                .catch(() => {
                    feature.isRemoved = !feature.isRemoved;
                })
                .finally(() => this.isRemovingInProgress = false);
        }
    }

    function canChangeActiveStatus(featureList) {
       return featureList.filter(x => x.isActive).length < 3;
    }
})();